import React, { useContext } from 'react'
import { Context } from '../../context/store';

function Platforms() {
    const [mainState, _] = useContext(Context);

    return (
        <>
            <option value="Referral">Referral</option>
            <option value="Extension">Extension</option>
            <option value="WalkIn">Walk In</option>
            {mainState.user.is_staff == 3 ?
                false
                :
                <>
                    <option value="Booking">Booking</option>
                    <option value="Airbnb">Airbnb</option>
                    <option value="Expedia">Expedia</option>
                    <option value="Agoda">Agoda</option>
                    <option value="HostelWorld">Hostel World</option>
                    <option value="Website">Website</option>
                    <option value="Other">Other</option>
                </>
            }
        </>
    );
}

export default Platforms;