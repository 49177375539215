import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../context/store';

function Notification(props) {
  const [mainState] = useContext(Context);
  const [isAlertVisible, setIsAlertVisible] = useState(true);
  useEffect(() => {
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 5000);
  }, [mainState.notification]);

  return (
    <>
      <div className='noti--container'>
        {isAlertVisible && mainState.notification.text ? <div className='alert-container '>
          <div className={'notify gr alert-inner ' + mainState.notification.type}>
            <span className='circle'>
              <i className="fas fa-check"></i>
            </span>
            <span>{mainState.notification.text}</span>
          </div>
        </div> : <></>
        }
      </div>
    </>
  );
}

export default Notification;
