import React, { useState, useEffect } from 'react';
import { getPrivate,post } from '../../api/requests/main';

export function AllMethod() {
    return (
        <>
            <option value="cash">Cash</option>
            <option value="online">Online</option>
            <option value="amr">Amr</option>
            <option value="bank">Bank</option>
            <option value="vCard">V-Card</option>
            <option value="pos">pos</option>
        </>
    );
}

export function SubMethod(mainState) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        post('accounts/list', { property: mainState.selected_Property })
            .then(response => {
                setOptions(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <>
            {options.map((option, index) => (
                option.permission_access && option.is_active ? <option key={index} value={option.name}>{option.name}</option> : false
            ))}
        </>
    );
}

export function OnlineMethod() {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getPrivate('accounts/list')
            .then(response => {
                setOptions(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <>
            {options.map((option, index) => (
                option.permission_access && option.is_active && option.is_online ? <option key={index} value={option.name}>{option.name}</option> : false
            ))}
        </>
    );
}


export function ExtraCharge() {
    return (
        <>
            <option value="parking">Parking</option>
            <option value="laundry">Laundry</option>
            <option value="ironing">Ironing</option>
            <option value="breakfast">Breakfast</option>
            <option value="lunch">Lunch</option>
            <option value="damage">Damage</option>
        </>
    );
}