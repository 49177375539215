import React, { useState, useRef, useEffect, useContext } from 'react'
import { post, postFormData } from '../../api/requests/main';
import { Context } from '../../context/store';
import { Notification } from '../../components/common/logic/Toggles';
import { country } from '../../helpers/getCountry';
import getBed from '../../helpers/getBed'
import { toast } from 'react-toastify';


function User(prop) {
    const [userInfo, setUserInfo] = useState(false);
    const [user] = useState(prop.user);
    const [auth] = useState(prop.auth);
    const [stat] = useState(prop.stat);
    const usrUpdate = useRef();

    const d = new Date();
    const [mainState, setMainState] = useContext(Context);
    const [m] = useState(mainState.selectedDate.m || d.getMonth() + 1);
    const [y] = useState(mainState.selectedDate.y || d.getFullYear());

    useEffect(() => {
        getUserInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getUserInfo = async () => {
        try {
            const response = await postFormData('get-user-by-id', { "id": user });
            if (response.status === 200) {
                setUserInfo(response.data.data)

            }
        } catch (error) { console.error(error); }

    }

    const updateGuest = async () => {
        try {
            const response = await post('guests/guest-edit', usrUpdate.current);
            toast('Guest updated!')
        } catch (error) { console.error(error); }
    }

    if (!userInfo) {
        return <div className='load' > <span className='tar'></span></div>
    }
    return (
        <>
            <br />
            {stat === 'noedit' ? <>
                <div className=' center'>
                    <h1 className='center'>{userInfo.user.name} {userInfo.user.lastname}  </h1>
                </div>
            </> :
                <form ref={usrUpdate}>
                    <div className='flex space'>
                        <input type='hidden' value={userInfo.user.id} name='id' />
                        <div> First Name : <input className="form-control" name='name' type='text' defaultValue={userInfo.user.name} /> </div>
                        <div> Last Name : <input className="form-control" name='lastname' type='text' defaultValue={userInfo.user.lastname} /> </div>
                    </div>
                    <div className='flex space'>
                        <div> Phone : <input className="form-control" name='phone' type='number' defaultValue={userInfo.user.phone} /> </div>
                    </div>
                    <br />
                    <p className='btn btn-primary' onClick={updateGuest} > Update User </p>
                </form>
            }
            <div className='flex space'>
                <div> Nationality : {country(userInfo.user.nationality)} </div>
                <div> Email : {userInfo.user.email} </div>
            </div>
            <div className='flex space'>
                <img className='sm-ps' src={process.env.REACT_APP_BUCKET + "/passport/" + userInfo.passport.image} />
                <img className='sm-ps' src={userInfo.user.signature} />
            </div>
            <br />
            {userInfo.payments.length ?
                <div className='card dark'>
                    <h5>Payments </h5>

                    {userInfo.payments.map((a, id) => {
                        return <div className={a.type + ' card-in'} key={id + 'crd'}>
                            <div> <h6>{a.type}</h6> </div>
                            <div className='flex space'>
                                <div>
                                    <div> <b>Date:</b> {(new Date(a.date)).toLocaleDateString()} </div>
                                    <div> <b>Payment Type:</b> {a.name}  </div>
                                </div>
                                <div>
                                    <div> <b>Amount:</b> {Number(a.amount).toFixed(1)} </div>
                                    <div> <b>Method:</b> {a.paymentMethod} </div>
                                </div>
                            </div>
                            <div> <b>Description:</b> {a.description}</div>
                        </div>
                    })}
                </div> :
                <div className='card dark empty'>
                    <h5>No Payments </h5>
                </div>
            }

            {userInfo.deposit.length ?
                <div className='card dark'>
                    <h5>Deposits </h5>

                    {userInfo.deposit.map((a, id) => {
                        return <div key={id + 'desp'} className={a.type + ' card-in'}>
                            <div> <h6>{a.type}</h6> </div>
                            <div className='flex space three'>
                                <div> <b>Date:</b> {(new Date(a.date)).toLocaleDateString()} </div>
                                <div> <b>Amount:</b> {Number(a.amount).toFixed(1)} </div>
                                <div> <b>Method:</b> {a.paymentMethod} </div>
                            </div>
                            <div> <b>Description:</b> {a.description}</div>
                        </div>
                    })}
                </div> :
                <div className='card dark empty'>
                    <h5>No Deposits </h5>
                </div>
            }
            {userInfo.bookingDate.length ?
                <div className='card dark'>
                    <h5>Booking Dates </h5>
                    <table className="table custom">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Amount</th>
                                <th scope="col">Bed Number</th>
                                <th scope="col">Booking Date</th>
                                <th scope="col">Platform</th>
                            </tr>
                        </thead>
                        {userInfo.bookingDate.map((a, id) => {
                            return <tbody key={id+"tb"}>
                                <tr>
                                    <td> {Number(a.amount).toFixed(2)} </td>
                                    <td> {getBed(a.bedNumber)} </td>
                                    <td> {a.bookingDate} </td>
                                    <td> {a.platform} </td>
                                </tr>
                            </tbody>
                        })}
                    </table>
                </div> : <div className='card dark empty'>
                    <h5>No Booking Dates </h5>
                </div>
            }
        </>
    )
}

export default User