export function getSelectedValuesFromNodeList(nodeList) {
    const selectedValues = [];
    if (!nodeList.length) {
        selectedValues.push(nodeList.value);
    } else {
        for (const element of nodeList) {
            if (element.checked) {
                selectedValues.push(element.value);
            }
        }
    }
    return selectedValues;
}