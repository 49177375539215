import React, { useState, useEffect } from 'react';
import { post } from '../../api/requests/main';

const PropertySelector = ({ a }) => {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await post('list-properties', {});
        setProperties(response.data);

      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, [a]);


  return (
    <>
        {properties.map((property) => (
          <option key={property.id} value={property.id}>
            {property.name}
          </option>
        ))}
    </>
  );
};

export default PropertySelector;
