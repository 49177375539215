import React, { useState, useRef, useEffect, useReducer, useContext } from 'react'
import Rooms from '../sub-componments/Rooms';
import { Notification } from '../../components/common/logic/Toggles';
import AsyncSelect from 'react-select/async';
import { Context } from '../../context/store';
import { getDaysBetweenDates } from '../../helpers/date';
import { post } from '../../api/requests/main';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {findRoomOrderByBed} from '../../helpers/findRange';
import Platforms from '../sub-componments/Platforms.js';

import { toast } from 'react-toastify';



const formReducer = (state, action) => {
    switch (action.type) {
        case "update":
            return { ...state, [action.name]: action.value };
        default:
            return state;
    }
};


const filterColors = (inputValue) => {
    const convertedData = inputValue.map(item => {
        const fullName = `${item.name.trim()} ${item.lastname.trim()}`;
        return {
            value: item.id,
            label: fullName
        };
    });
    return convertedData;
};

const promiseOptions = async (inputValue) => {
    return new Promise(async (resolve) => {
        if (inputValue.length > 2) {
            try {
                const response = await post('search-users', { "name": inputValue });
                if (response.status === 200) {
                    resolve(filterColors(response.data.data));
                }
            } catch (error) {
                // handle error
            }
        }
    });
}
function ReCheckin(p) {
    const [disable, setDisable] = useState(false);
    const [mainState, setMainState] = useContext(Context);
    const [cacheOptions] = useState(true);
    const [defaultOptions] = useState(true);
    const [totalDays, setTotalDays] = useState('0');
    const inputRef = useRef(null);

    const [selectedGuest, setSelectedGuest] = useState(null);
    const formRef = useRef("");
    const [value, onChange] = useState([new Date(), new Date()]);
    let bDate = p.bDate[p.bDate.length - 1];

    const [formState, dispatch] = useReducer(formReducer, {
        checkin: "",
        checkout: "",
        amount: 0,
        bedNumber: bDate.bedNumber,
        platform: "Extension",
        description: '',
        pricePerDay: bDate.amount
    });

    useEffect(() => {
        if (p.userId) {
            setDefualtUser(p.userId)
        }
    }, []);

    useEffect(() => {
        setTotalDays(
            getDaysBetweenDates(value[0], value[1]) - 1
        )
    }, [formState, value]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        dispatch({ type: "update", name, value });
    };




    const setDefualtUser = async (a) => {
        try {
            const response = await post('get-user-by-id', { "id": a });
            if (response.status === 200) {
                setSelectedGuest(response.data.data.user)
            }
        } catch (error) {
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            !selectedGuest?.id ||
            !value[0] ||
            !value[1]
        ) {
            setDisable(false)
            toast.error('Please Fill all the fields')
            return
        }

        formState.id = selectedGuest.id

        formState.checkin = new Date(value[0]);
        formState.checkin.setDate(formState.checkin.getDate() + 1);
        formState.checkin = formState.checkin.toISOString().split('T')[0];

        formState.checkout = new Date(value[1]);
        formState.checkout = formState.checkout.toISOString().split('T')[0];


        try {
            const response = await post('add-full-checkin', formState);
            if (response.status === 201) {
                toast('Check-in Saved!')
                p.popup(false)
            }
        } catch (error) {
            toast.error('Something went wrong!')
        } finally {
            setDisable(false)
        }
    }

    const getNewUser = async (a) => {
        setSelectedGuest(undefined)
        try {
            const response = await post('get-user-by-id', { "id": a.value });
            if (response.status === 200) {
                setSelectedGuest(response.data.data.user)
            }
        } catch (error) {
        }
    };

    const getPrice = async (event) => {
        const dateRange = [];
        const currentDate = new Date(value[0]);
        const lastDate = new Date(value[1]);

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        while (currentDate < lastDate) {
            if (currentDate.getDate() !== lastDate.getDate()) {
                dateRange.push(formatDate(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        if (formatDate(currentDate) == formatDate(lastDate)) {
            toast('Please select date')
            return
        }
        const prc = await post('get-new-avilablilty', dateRange)

        if (prc.status === 200) {
            let totalPrice = 0;
            const RoomNumber = findRoomOrderByBed(formRef.current.bedNumber.value);
            const daysArray = Object.values(prc.data.days)[RoomNumber - 1];
            
            for (let s of daysArray) {
                if (!s.price) {
                    toast("Selected days not available");
                    break;
                }
                totalPrice += s.price;
            }

            let finalPrice = Number(totalPrice / dateRange.length).toFixed(2);
            dispatch({ type: "update", name: "amount", value: totalPrice });
            inputRef.current.value = finalPrice;
            dispatch({ type: "update", name: "pricePerDay", value: finalPrice });
        }

    };


    return (
        <>
            <section>
                <form ref={formRef}>
                    {p.userId ? <></> :
                        <label>Name
                            <AsyncSelect onChange={getNewUser} cacheOptions={cacheOptions} defaultOptions={defaultOptions} loadOptions={promiseOptions} />
                        </label>
                    }

                    {selectedGuest === undefined ? <div className='card dark flex'>Loading ...</div> : ""}
                    {selectedGuest ?
                        <div className='card dark flex full-wd'>
                            Name: {selectedGuest.name} {selectedGuest.lastname} <br />
                            Phone: {selectedGuest.phone}
                            <p> Last Rate: <b>{Number(bDate.amount).toFixed(2)}</b> ({bDate.bookingDate}) </p>
                        </div>
                        : ""
                    }
                    <div className='flex'>
                        <label>Price Per Day
                            <input type="number"
                                ref={inputRef}
                                defaultValue={bDate.amount}
                                onWheel={(e) => e.target.blur()}
                                onChange={handleChange}
                                name="pricePerDay"
                                required="required" className="form-control" />
                        </label>
                    </div>
                    <div className='flex genPrice'>
                        <label>
                            Check-in : (<b>{totalDays} </b>Days)
                            <DateRangePicker onChange={onChange} value={value} className="form-control" />
                        </label>
                        <p className='btn' onClick={getPrice}> Get Price </p>
                    </div>
                    <div className='flex'>

                        <label>
                            <span>Platform: </span>
                            <select className='form-select' defaultValue={'Extension'} name='platform' onChange={handleChange} required="required" >
                                <Platforms />
                            </select>
                        </label>
                    </div>
                    <textarea name='description' className='textArea' onChange={handleChange} placeholder=' Write here any additional notes for the guest'></textarea>
                </form>
            </section>
            <footer>
                <button type="submit" onClick={handleSubmit} disabled={disable} className="btn btn-success main">Extend</button>
            </footer>
        </>
    )
}

export default ReCheckin