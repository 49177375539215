export function getDaysBetweenDates(date1, date2) {
    if (!date1 || !date2) {
        return 0;
    }

    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return Number(diffDays);

}
