import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Store } from './context/store';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

const enableStrictMode = process.env.REACT_APP_DEBUG === 'True';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  enableStrictMode ? ( 
    <React.StrictMode>
        <Store>
          <App />
        </Store>
    </React.StrictMode>
  ) : (
      <Store>
        <App />
      </Store>
  )
);

reportWebVitals();
