import React, { useContext, useEffect, useState } from 'react'
import { get, post } from '../api/requests/main';
import { Context } from '../context/store';
import { formatTimestamp } from '../helpers/timeFormat';

function DoorKey() {
    const [mainState, setMainState] = useContext(Context);
    const [data, setData] = useState([]);
    useEffect(() => {
        getcalender()
    }, [])


    async function deleteRec(id) {
        alert("Still Being Developed :D ")
        return;
    }
    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })
        try {
            const response = await get('lock-access/list-key');
            if (response.status === 200) {

                setData(response.data)
            }


        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };


    return (
        <>

            <div className='container center'>
                <br />
                <br />
            </div>
            <div className='container'>
                <div className='row'>
                    <table className='table custom table-white vc'>
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Status</th>
                                <th>Start</th>
                                <th>Ends</th>
                                <th>Room Number</th>
                                <th>Booking Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length ?
                                data.map((a, id) => {
                                    return <tr id={id} key={id} >
                                        <td> {a?.code}</td>
                                        <td> {a?.status}</td>
                                        <td> {formatTimestamp(a.starts_at)}</td>
                                        <td> {formatTimestamp(a.ends_at)}</td>
                                        <td> {a?.guest?.name}
                                            {a?.guest?.email}</td>
                                        <td>{a?.booking?.status}</td>
                                        <td><button className='btn btn-main danger' onClick={() => { deleteRec(a.id) }}>Remove</button></td>
                                    </tr>

                                }) : <tr>
                                    <td colspan="7">
                                        NO RECORDS TO DISPLAY
                                    </td>
                                </tr>}
                        </tbody >
                    </table>
                </div>
            </div>
        </>
    )
}

export default DoorKey;