import { useLocation } from 'react-router-dom';

function Stripe() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const amount = queryParams.get('amount');
    return (
        <div className='container center style'>
            {status === 'cancelled' ?
                <>The request has been cancelled</> :
                <></>
            }
            {status === 'fail' ?
                <> The request has failed</> :
                <></>
            }
            {status === 'true' ?
                <>
                    <h5>The Payment has been success </h5>
                    <p>Thank you for using Holiday Mate Payment Service.</p>
                </>
                :
                <></>
            }
        </div>
    );
}

export default Stripe;
