export function getById(objects, id) {
    return objects.find(obj => obj.bid === id);
}

export function getBedName(beds, bedID) {
    let count = 0;

    for (let room of beds) {
        for (let bed of room.beds) {
            if (count === bedID - 1) {
                return bed;
            }
            count++;
        }
    }

    return null; // Return null if the bedID is out of range
}

export const letrs = ['', "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1", "K1", "L1", "M1", "N1", "O1", "P1", "Q1"];
