import { Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import { Context } from '../../context/store';

const PrivateRoutes = () => {
    const [mainState, setMainState] = useContext(Context);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        const configurations = localStorage.getItem('configurations');

        if (token && !mainState.token) {
            setMainState({ type: 'token', payload: token });
        }

        if (user && !mainState.user) {
            setMainState({ type: 'user', payload: JSON.parse(user) });
        }
        if (configurations && !mainState.configurations) {
            setMainState({ type: 'configurations', payload: JSON.parse(configurations) });
        }
    }, [setMainState]); // Only run once on mount

    return mainState.token || localStorage.getItem('token') ? (
        <Outlet />
    ) : (
        <Navigate to='/login' replace />
    );
};

export default PrivateRoutes;
