
export function booking(arr) {
    let total = 0;
    if (arr?.length === 0 || !arr || !arr?.length) { return 0; }
    arr.forEach(obj => {
        total += parseFloat(obj.amount);
    });
    return total.toFixed(4);
}


export function payment(arr) {
    let total = 0;
    if (arr?.length === 0 || !arr || !arr?.length) { return 0; }
    arr.forEach(transaction => {
        if (transaction.type === "income") {
            total += parseFloat(transaction.amount);
        } else {
            total -= parseFloat(transaction.amount);
        }
    });
    return total.toFixed(4);
}

export function deposit(arr) {
    let total = 0;
    if (arr?.length === 0 || !arr || !arr?.length) { return 0; }
    arr.forEach(transaction => {
        if (transaction.type === "income") {
            total += parseFloat(transaction.amount);
        } else {
            total -= parseFloat(transaction.amount);
        }
    });
    return total.toFixed(4);
}


export function calc(paym, book) {
    let pay = Number(payment(paym))
    pay = pay - Number(booking(book))
    return pay
}
export function calcTwo(one, two, book) {
    let pay = Number(payment(one)) + Number(payment(two))
    pay = pay - Number(booking(book))
    return pay
}

export function getExSer(array) {
    return array.filter(item => item.name.toLowerCase() !== "booking");
}