import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import { postFormData } from '../api/requests/main';

function AutoBooking() {
    const [userInfo, setUserInfo] = useState(false);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const id = queryParams.get('id');


    useEffect(() => {
        getUserInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getUserInfo = async () => {
        try {
            const response = await postFormData('get-user-by-id', { "id": id });
            if (response.status === 200) {
                setUserInfo(response.data.data)
            }
        } catch (error) { console.error(error) }

    }

    if (id && token && token === "eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ") {
        return (
            userInfo ?
                <div className='container bok-extnd'>
                    <h1> Alphatel </h1>
                    <p>Welcome {userInfo.user.name} {userInfo.user.lastname}</p>
                </div>
                : <></>
        );
    } else {
        return (null)
    }
}

export default AutoBooking;