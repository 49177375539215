import React, { useContext, useEffect, useState } from 'react'
import { getPrivate,post, listVcards, transferVcard } from '../../api/requests/main';
import { Context } from '../../context/store';

import { calcAll } from '../../helpers/getTotalAmount';
function Vcards() {
    const d = new Date();
    const [mainState, setMainState] = useContext(Context);

    const [data, setData] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [m, setM] = useState(mainState.selectedDate.m || d.getMonth() + 1);
    const [y, setY] = useState(mainState.selectedDate.y || d.getFullYear());


    // const 
    useEffect(() => {
        getcalender()
        setMainState({ type: 'selectedDate', payload: { 'y': Number(y), 'm': Number(m) } });
    }, [m, y, setY, setM])
    useEffect(() => {
        fetchPaymentMethods()

    }, [])

    const fetchPaymentMethods = async () => {

        post('accounts/list', { property: mainState.selected_Property })
            .then(response => {
                setPaymentMethods(response.data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };



    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })

        try {
            const response = await listVcards({ "year": y, "month": Number(m) });
            if (response.status === 200) {
                setData(response.data);
            }
        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };

    const viewUser = (s) => {
        setMainState({ type: "userId", payload: s });
    }
    async function handleSave(a) {
        let mainElm = a.target.parentElement.parentElement
        let paymentMethod = mainElm.querySelector('[name="paymentMethods"]').value

        let dat = {
            "pid": a.target.id,
            "uid": mainState.user.id,
            "paymentMethod": paymentMethod
        }
        var answer = window.confirm("are you sure that you want to collect the V-Card?");

        if (answer === true) {
            try {
                await transferVcard(dat);
            } catch (error) {

            } finally {
                setMainState({ type: 'loader', payload: false })
                getcalender()
            }
        }
    }


    return (
        <>
            <div className='container center'>
                <br />
                <div>
                    <h2 style={{textAlign:'center'}}> uncollected Virtual credit card amount <b style={{ color: "color: rgb(21 198 229);" }}>{calcAll(data).toFixed(2)}</b> </h2>
                </div>
                <br />
            </div>
            <div className='container'>
                <div className='row'>
                    <table className='table custom table-white vc'>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th width="50"></th>
                        </tr>
                        {data.map((a) => {
                            return <tr id={a.id} key={a.id} >
                                <td className='click a' onClick={() => { viewUser(a.gid_id) }}  >{a.guests.name} {a.guests.lastname}</td>
                                <td>{a.amount}</td>
                                <td >{a.description}</td>
                                <td >{new Date(a.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                <td className='flex-select'>
                                    <select className="sm form-select" name="paymentMethods" >
                                        {paymentMethods.map((method) => (
                                            method.id && method.is_active && method.is_online ? <option key={method.id} value={method.name}>{method.name}</option> : false
                                        ))}
                                    </select>
                                    <button onClick={(s) => { handleSave(s) }} id={a.id} className='btn btn-primary'>Collect </button>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </div>
        </>
    )
}

export default Vcards