import React from 'react';
import booking from '.././../assets/channel-icons/booking.svg';
import airbnb from '.././../assets/channel-icons/airbnb.svg';
import agoda from '.././../assets/channel-icons/agoda.svg';
import expedia from '.././../assets/channel-icons/expedia.svg';
import extension from '.././../assets/channel-icons/extension.svg';
import googleHotelARI from '.././../assets/channel-icons/google.svg';
import hostelWorld from '.././../assets/channel-icons/hostelworld.svg';
import other from '.././../assets/channel-icons/other.svg';
import referral from '.././../assets/channel-icons/referral.svg';
import walkIn from '.././../assets/channel-icons/walkIn.svg';
import website from '.././../assets/channel-icons/website.svg';

const icons = {
  Agoda: agoda,
  Airbnb: airbnb,
  Booking: booking,
  Expedia: expedia,
  Extension: extension,
  GoogleHotelARI: googleHotelARI,
  HostelWorld: hostelWorld,
  Hostelworld: hostelWorld,
  Other: other,
  Referral: referral,
  WalkIn: walkIn,
  Website: website,
};

const Channel = ({ name }) => {
  const Icon = icons[name] || other;
  return (
    <div className='channel-icon-sm'>
      <img src={Icon} alt={name} />
      <span style={{ marginLeft: '4px' }}>{name}</span>
    </div>
  );
};

export default Channel;
