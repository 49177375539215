export function findRoomOrderByBed(bedNumber) {
  const rooms = JSON.parse(JSON.parse(localStorage.getItem('configurations')).rooms);
    let cumulativeBeds = 0;
    
    for (let i = 0; i < rooms.length; i++) {
        cumulativeBeds += rooms[i].beds.length;
        if (bedNumber <= cumulativeBeds) {
            return i + 1;
        }
    }
    return -1;
}

export default function findRange(number) {
  try {

    const ranges = JSON.parse(JSON.parse(localStorage.getItem('configurations')).rooms);

    return ranges[number].room_name
  } catch (error) {
    console.error("Failed to parse", error);
  }

  return -1;
}

