import { useState, useEffect } from 'react';
import React from 'react';
import Modal from 'react-modal';
import { post } from '../../../../api/requests/main';
import './CreditCard.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: 'none',
        maxWidth: '500px',
        width: '90%',
    },
};

function CreditCard({ id }) {
    const [data, setData] = useState(undefined);
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setData(undefined);
        setIsOpen(true);
    }

    async function getCCARD() {
        const response = await post("get-credit-card-info", id);
        setData(response.data);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="end">
            <span className="a" onClick={openModal}>Credit Card</span>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                onAfterOpen={getCCARD}
                style={customStyles}
                contentLabel="Credit Card Info"
                ariaHideApp={false}
            >
                <div className="modal-body">
                    {data === undefined ? (
                        <div className="center">Loading...</div>
                    ) : (
                        data ?

                            <div className='grid-p'>
                                {/* <p><strong>Card Type:</strong> </p> */}
                                <p><strong>Cardholder Name:</strong> {data.cardholder_name}</p>
                                <p><strong>Card Number:</strong> <span>  {data.card_number} <span className='spn'> {data.card_type == "MA" ? "MASTER CARD" : "VISA"} </span></span></p>
                                <p><strong>Expiration Date:</strong> {data.expiration_date}</p>
                            </div> :
                            <div className="center">NOT FOUND.</div>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default CreditCard;
