import * as React from "react";
import { useContext } from 'react';
import { Outlet, useOutletContext } from "react-router-dom"
import { Context } from "./context/store";
import { redirect } from "react-router-dom";
import { NavLink } from 'react-router-dom';


function Main() {
    const [mainState] = useContext(Context);
    const logout = () => {
        localStorage.clear();
        window.location.reload();
        return redirect("/login");
    }
    return (
        <>

            <div className="header ">
                <div className="nop">
                    <div className="header-menu">
                        <p> Holiday Mate </p>
                        <label className="input-menu" htmlFor="menu" >
                            <i className="fa-solid fa-bars"></i>
                        </label>
                        <input type="checkbox" id="menu" />
                        {mainState.user.is_staff ?
                            <>
                                <ul>
                                    <li> <NavLink activeclassname="active" to=""> Dashboard</NavLink> </li>
                                    <li> <NavLink activeclassname="active" to="table"> Calendar</NavLink> </li>
                                    <li><NavLink activeclassname="active" className={"h-btn"} to="booking-list">Report</NavLink></li>
                                    <li className="listholder">
                                        <span>
                                            Guest
                                        </span>
                                        <ul>
                                            <li> <NavLink activeclassname="active" to="add-new-guest">  Add Guest</NavLink> </li>
                                            <li><NavLink activeclassname="active" to="users">View Guest</NavLink></li>
                                            {/* <li><NavLink activeclassname="active" to="payment">Payments</NavLink></li> */}
                                        </ul>
                                    </li>
                                    {/* <li><NavLink activeclassname="active" to="chat"> Chat</NavLink></li> */}
                                    {/* <li><NavLink activeclassname="active" to="v-cards"> V-Cards</NavLink></li> */}
                                    <li><NavLink activeclassname="active" to="pricing"> Pricing</NavLink></li>
                                    <li> <a onClick={logout} className="red">  Logout</a> </li>

                                    {/* <li className="listholder">
                                        <span>
                                            Other
                                        </span>
                                        <ul>
                                            <li><NavLink activeclassname="active" to="channel-manager">Channel Manager</NavLink></li>
                                            <li><NavLink activeclassname="active" to="waitlist-website"> Website Leads</NavLink></li>
                                            <li><NavLink activeclassname="active" to="current-cash">Current Cash flow</NavLink></li>
                                            <li><NavLink activeclassname="active" to="expenses">Expenses</NavLink></li>
                                            <li><NavLink activeclassname="active" to="booking-logs"> Logs</NavLink></li>
                                            <li><NavLink activeclassname="active" to="door-key"> Door Access</NavLink></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </>

                            : <>
                                <ul>
                                    <li> <NavLink activeclassname="active" to=""> Home</NavLink> </li>
                                    <li> <NavLink activeclassname="active" to="table"> Bookings</NavLink> </li>
                                    <li className="listholder">
                                        <span>
                                            Guest
                                        </span>
                                        <ul>
                                            <li> <NavLink activeclassname="active" to="add-new-guest">  Add Guest</NavLink> </li>
                                            <li><NavLink activeclassname="active" to="users">View Guest</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink activeclassname="active" to="chat"> Chat</NavLink></li>
                                    <li className="listholder">
                                        <span>
                                            Other
                                        </span>
                                        <ul>
                                            <li><NavLink activeclassname="active" to="door-key"> Door Access</NavLink></li>
                                            <li> <a onClick={logout} className="red">  Logout</a> </li>
                                        </ul>
                                    </li>
                                </ul>
                            </>}
                    </div>
                </div>
            </div >
            <div className="body">
                <Outlet context={useOutletContext()} />
            </div>
        </>

    );
}

export default Main;
