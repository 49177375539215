import React, { useEffect, useState } from 'react';
import { post } from '../../api/requests/main';

const ChannelIframe = ({ propertyId, groupId, username }) => {
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    const fetchIframeUrl = async () => {
      try {
        const response = await post('channex-generate-iframe-url', {});
        setIframeUrl(response.data.iframeUrl);
      } catch (error) {
        console.error('Error fetching iframe URL:', error);
      }
    };

    fetchIframeUrl();
  }, [propertyId, groupId, username]);

  return (
    <div>
      {iframeUrl ? (
        <iframe src={iframeUrl} width="100%" height="100%" style={{ height: "100%", minHeight: 'calc(100vh - 60px)' }} frameBorder="0" allowFullScreen></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ChannelIframe;
