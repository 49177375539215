import React, { useContext, useEffect, useRef, useState } from 'react'
import { get, getCash } from '../../api/requests/main';
import { Context } from '../../context/store';
import { payment } from '../../helpers/getTotal';
import { post } from '../../api/requests/main';
import { postFormData } from '../../api/requests/main';
import Select from 'react-select'
import { formatDate } from '../../helpers/dateFormat';
import { AllMethod } from '../sub-componments/paymentMethod';
import { toast } from 'react-toastify';

function Cash() {
    const d = new Date();
    const [mainState, setMainState] = useContext(Context);

    const [Transfer, setTransfer] = useState(false);
    const [Expense, setExpense] = useState(false);
    const [expenseList, setExpenseList] = useState([]);
    const [savingExpense, setSavingExpense] = useState(false);
    const [data, setData] = useState({ "payment": [], "pos": [], "cash": [] });
    const [m, setM] = useState(mainState.selectedDate.m || d.getMonth() + 1);
    const [y, setY] = useState(mainState.selectedDate.y || d.getFullYear());
    const TransferRef = useRef("")
    const ExpenseRef = useRef("")


    // maintnince

    // const 
    useEffect(() => {
        getcalender()
        setMainState({ type: 'selectedDate', payload: { 'y': Number(y), 'm': Number(m) } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [m, y, setY, setM])

    useEffect(() => {
        getExpenseList()
    }, [])

    const getExpenseList = async () => {
        setMainState({ type: 'loader', payload: true });

        try {
            const response = await get('expense/list');
            if (response.status === 200) {
                const restructuredList = response.data.map(expense => {
                    const label = expense.split(/(?=[A-Z])/).join(' ').replace(/^[a-z]/, (m) => m.toUpperCase());
                    return { label, value: expense };
                });
                setExpenseList(restructuredList);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };
    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })

        try {
            const response = await getCash({ "y": y, "m": Number(m) });
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };

    const saveTransfer = async (a) => {
        if (TransferRef.current.amount.value || TransferRef.current.amount.value !== "") {
            try {
                const response = await post('transfer-cash', TransferRef.current);
                if (response.status === 201 || response.status === 200) {
                    toast('Cash Transfered!')
                    setTransfer(false)
                    getcalender()
                }
            } catch (error) { console.error(error); }
        }
    }
    const saveExpense = async (a) => {
        if (ExpenseRef.current.amount.value || ExpenseRef.current.amount.value !== "") {
            // Check if the function is already running
            if (savingExpense) {
                return; // Exit the function if it's already running
            }

            try {
                // Set the flag to indicate that the function is running
                setSavingExpense(true);

                const response = await postFormData('payment-add', ExpenseRef.current);
                if (response.status === 201 || response.status === 200) {
                    toast('Payment added!')
                    setExpense(false)
                    getcalender()
                }
            } catch (error) {
                console.error(error);
            } finally {
                // Reset the flag when the function is done
                setSavingExpense(false);
            }
        } else {
            alert("Please Fill all the fields")
        }
    }
    let amrAM = Number(payment(data.payment))
    let cashAM = Number(payment(data.cash))
    let posAM = Number(payment(data.pos))

    return (
        <>
            <div>
                <br />
                <div className='container'>
                    <h2>
                        Amr's Account: <b>{amrAM}</b> <br /> Cash: <b>{cashAM}</b>
                    </h2>
                    <div className='flex'>
                        <span>POS: {posAM}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <p className='btn btn-primary' onClick={a => { setExpense(true) }}> Add Expense </p>
                <p className='btn btn-secondary' onClick={a => { setTransfer(true) }}> Transfer Money </p>
            </div>

            <div className='container card '>
                <h4> Amr's Account - last 40 Breakdown </h4>
                <table className='table custom max10 table-white vc'>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Description</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            let Nnew = amrAM;
                            return (
                                <>
                                    {data.payment.map((a, id) => {
                                        if (a.type === "income") {
                                            Nnew -= Number(a.amount)
                                        } else {
                                            Nnew += Number(a.amount)
                                        }
                                        return (
                                            id < 40 ?
                                                <tr className={'tr ' + a.type + " num" + id} key={"num" + id} >
                                                    <td>{formatDate(a.date)}</td>
                                                    <td>{a.name}</td>
                                                    <td>{a.description}</td>
                                                    <td>{Number(a.amount).toFixed(0)}</td>
                                                    <td>{a.type === "income" ? Number(Number(Nnew) + Number(a.amount)).toFixed(2) : Number(Number(Nnew) - Number(a.amount)).toFixed(2)}</td>
                                                </tr> : false
                                        );
                                    })}
                                </>
                            );
                        })()}
                    </tbody>
                </table>
            </div>
            <br />
            <br />
            <br />
            <div className='container card '>
                <h4>
                    Cash - last 40 Breakdown
                </h4>
                <table className='table custom max10 table-white vc'>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Description</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            let Nnew = cashAM;
                            return (
                                <>
                                    {data.cash.map((a, id) => {
                                        if (a.type === "income") {
                                            Nnew -= Number(a.amount)
                                        } else {
                                            Nnew += Number(a.amount)
                                        }
                                        return (
                                            id < 40 ?
                                                <tr className={'tr ' + a.type + " num" + id} key={"num" + id} >
                                                    <td>{formatDate(a.date)}</td>
                                                    <td>{a.name}</td>
                                                    <td>{a.description}</td>
                                                    <td>{Number(a.amount).toFixed(0)}</td>
                                                    <td>{a.type === "income" ? Number(Nnew + Number(a.amount)).toFixed(2) : Number(Nnew - Number(a.amount)).toFixed(2)}</td>
                                                </tr> : false
                                        );
                                    })}
                                </>
                            );
                        })()}

                    </tbody>
                </table>
            </div>
            <div className='container card '>
                <h4> POS - last 40 Breakdown </h4>
                <table className='table custom max10 table-white vc'>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type</th>
                            <th scope="col">Description</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            let Nnew = 0;
                            return (
                                <>
                                    {data.pos.map((a, id) => {
                                        if (a.type === "income") {
                                            Nnew -= Number(a.amount)
                                        } else {
                                            Nnew += Number(a.amount)
                                        }
                                        return (
                                            id < 40 ?

                                                <tr className={'tr ' + a.type + " num" + id} key={"num" + id} >
                                                    <td>{formatDate(a.date)}</td>
                                                    <td>{a.name}</td>
                                                    <td>{a.description}</td>
                                                    <td>{Number(a.amount).toFixed(0)}</td>
                                                    <td>{a.type === "income" ? Number(Nnew + Number(a.amount)).toFixed(2) : Number(Nnew - Number(a.amount)).toFixed(2)}</td>
                                                </tr> : false
                                        );
                                    })}
                                </>
                            );
                        })()}

                    </tbody>
                </table>
            </div>
            <br />
            <br />
            <br />
            {Transfer ? <div className='popup '>
                <div className='card child dark pt-4' >
                    <header className='flex'>
                        <h4>New Money Transfer </h4>
                        <span onClick={() => { setTransfer(false) }}><i className="fa-solid fa-xmark"></i></span>
                    </header>
                    <section>
                        <div className='flex'>
                            <span>Amr: {Number(payment(data.payment))}</span>
                            <span>POS: {Number(payment(data.pos))}</span>
                        </div>
                        <form ref={TransferRef}>
                            <div className='input-50'>

                                <span>
                                    Amount:
                                    <input type='number' onWheel={(e) => e.target.blur()} className="form-control" name='amount' placeholder='amount' />
                                </span>
                                <span className='hide'>
                                    Date:
                                    <input name='date' type='date' className="form-control" defaultValue={new Date().toISOString().substr(0, 10)} />
                                </span>
                            </div>
                            <div className='input-50'>
                                <span>From:
                                    <select name='from' className="form-select" defaultValue={"cash"} >
                                        <AllMethod />
                                    </select>
                                </span>
                                <span>To:
                                    <select name='to' className="form-select" defaultValue={"bank"} >
                                        <AllMethod />
                                    </select>
                                </span>
                            </div>

                            Description: <textarea name='description' className="form-control" placeholder='Description'></textarea>
                            <input name='uid_id' type='hidden' defaultValue={mainState.user.id} />
                            <input name='type' type='hidden' defaultValue='outgoing' />
                        </form>

                    </section>
                    <footer> <p className='btn btn-primary' onClick={saveTransfer}> Add </p> </footer>
                </div>
            </div> : <></>}
            {Expense ? <div className='popup '>
                <div className='child wide' >
                    <header className='flex'>
                        <h4>Add New Expense </h4>
                        <span onClick={() => { setExpense(false) }}><i className="fa-solid fa-xmark"></i></span>
                    </header>
                    <section>
                        <form ref={ExpenseRef}>
                            <label>Name <Select name='name' required options={expenseList} defaultValue={expenseList[0]} /> </label>
                            <br />
                            <label>
                                Amount: <input className='form-control' type='number' onWheel={(e) => e.target.blur()} name='amount' placeholder='amount' />
                            </label>
                            <br />
                            Description: <textarea name='description' className='form-control' placeholder='POS Description'></textarea>
                            <label className='hide'>
                                date
                                <input name='date' className='form-control' type='date' readOnly defaultValue={new Date().toISOString().substr(0, 10)} />
                            </label>

                            <label>
                                Attachment
                                <input type="file" capture="user" name="image" accept='image/*' className="form-control" />
                            </label>
                            <br />
                            <input name='type' type='hidden' defaultValue='outgoing' />
                            <input name='paymentMethod' type='hidden' defaultValue='amr' />
                            <input name='uid_id' type='hidden' defaultValue={mainState.user.id} />
                        </form>
                    </section>
                    <footer> <p className='btn btn-primary' onClick={saveExpense}> Add </p> </footer>

                </div>
            </div> : <></>}
        </>
    )
}

export default Cash