import React, { useContext } from 'react'
import { Context } from '../../context/store';

function BookStatus() {
    const [mainState, _] = useContext(Context);

    return (
        <>
            <option value="reserved">Reserved</option>
            <option value="authorized">Authorized</option>
            {mainState.user.is_staff == 3 ?
                false
                :
                <>
                    <option value="paid">Paid</option>
                    <option value="checkIn">Check-in</option>
                    <option value="checkInPaid">Check-in [Paid]</option>
                    <option value="noShow">Paid no-show</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="waitlist">Waiting List</option>
                </>
            }
        </>
    );
}

export default BookStatus;