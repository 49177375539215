import { useState, useEffect, useContext } from 'react';
import { post } from '../api/requests/main';
import { NavLink } from 'react-router-dom';
import { Context } from '../context/store';
import Skeleton from 'react-loading-skeleton';
import { letrs } from '../helpers/getById';
import BookStatus from './sub-componments/Bookstatus';
import Channel from './components/platform';
import DatePicker from 'react-date-picker';

function Dashboard() {
    const [mainState, setMainState] = useContext(Context);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [capacity, setCapacity] = useState(0);
    const [date, setDate] = useState(new Date());
    const [data, setData] = useState({});
    const [price, setPrice] = useState(null);
    const [table, setTable] = useState([]);
    const [activeDiv, setActiveDiv] = useState('arrivals');

    const handleDivClick = (array) => {
        setTable(array);
    };

    const getData = async () => {
        try {
            const resMain = await post("main/dashboard", { date: date.toLocaleDateString('en-CA') });
            const resPrice = await post("get-new-avilablilty", [date.toLocaleDateString('en-CA')]);

            if (resPrice.status === 200) {
                setCapacity(resPrice.data.days.length)
                const parsedPrices = resPrice.data.days.reduce((acc, property) => {
                    acc[property.name] = property.prices;
                    return acc;
                }, {});
                setPrice(parsedPrices);
            } else {
                setPrice(null);
            }

            if (resMain.status === 200) {
                setData(resMain.data);
                setTable(resMain.data[activeDiv] || []);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData();
    }, [date]);

    const handleSave = async (bookings) => {
        let mainElm = bookings.target.parentElement.parentElement;
        let id = mainElm.id;
        let BookStatus = mainElm.querySelector('[name="BookStatus"]');
        let dat = { "id": id, "bookingStatus": BookStatus.value };
        try { await post('update-booking-info', dat); getData(); }
        catch (error) { console.error(error); }
    };

    const renderPrice = () => {
        if (!price) {
            return <div><Skeleton count={3} style={{ margin: '5px 0px 4px', height: '20px' }} /></div>;
        }
        return Object.keys(price).map(key => {
            const data = price[key][0];
            return (
                <b className='price' key={key}>
                    <span className='roomName'> {key} </span><span className='roomRate'>{data.price}</span>
                </b>
            );
        });
    };

    return (
        <div className="dashboard">
            <header className="container">
                <DatePicker onChange={setDate} value={date} autoFocus />
            </header>
            <section className="container">
                <div className="today">
                    <span className="day">{getDayName(new Date(date))}</span>
                    <span className="num">{new Date(date).getDate()}</span>
                    <span className="mon">{monthNames[new Date(date).getMonth()]}</span>
                </div>
                <div className="Rate">
                    <h4>Rate </h4>
                    {renderPrice()}
                </div>
                <div className="occupancy">
                    <h4>Properties </h4>
                    <p><span>{data?.booking || 0}</span> / {capacity}</p>
                    <ul>
                        <li className='booked'><span>{data?.booking || 0}</span> <span>Booked</span></li>
                        <li className='available'><span>{capacity - data?.booking || 0}</span> <span>Available</span></li>
                    </ul>
                </div>
                <div className="table">
                    <header>
                        <div className='left'></div>
                        <div className='right'>
                            <p>Reservation</p>
                            <h3>Activity</h3>
                        </div>
                    </header>
                    <section>
                        {['arrivals', 'departures', 'waitlist', 'reserved', 'noShow', 'cancelled'].map((key) => (
                            <div
                                key={key}
                                className={activeDiv === key ? 'active' : ''}
                                onClick={() => { handleDivClick(data?.[key]); setActiveDiv(key); }}
                            >
                                {data?.[key] ? <h3>{data?.[key]?.length}</h3> : <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />}
                                <h5>{key.charAt(0).toUpperCase() + key.slice(1)}</h5>
                            </div>
                        ))}
                    </section>
                    <table className='table custom table-white vc'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Platform</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Bed</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {table?.map((a, id) => (
                                <tr id={a.bid} key={id + activeDiv}>
                                    <td className='nowrap'>
                                        <b className='a' onClick={() => { setMainState({ type: "userId", payload: a.gid }); }}>{a?.guest_name}</b>

                                    </td>
                                    <td>
                                        <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}>
                                            <Channel name={a.platform} />
                                        </b>
                                    </td>
                                    <td>{a.bookingDate}</td>
                                    <td>{Number(a.amount).toFixed(2)}</td>
                                    <td>{letrs[a.bedNumber]}</td>
                                    <td className='flex-select'>
                                        <select className="sm form-select" name="BookStatus" defaultValue={a.status}>
                                            <BookStatus />
                                        </select>
                                        <button onClick={(s) => { handleSave(s) }} className='btn btn-primary'>Update</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
}

function getDayName(date) {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayNames[date.getDay()];
}

export default Dashboard;