import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/store';
import { post } from '../../api/requests/main';
function GPT() {
    const [mainState, setMainState] = useContext(Context);
    const [msgs, setMsgs] = useState([]);
    const [txt, setTxt] = useState('');

    useEffect(() => {
        getList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getList = async (a) => {

        setMainState({ type: 'loader', payload: true })
        try {
            const response = await post('smart/ai-list');
            if (response.status === 200) {
                setMsgs(response.data)
            }
        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    }
    const newMSG = async (a) => {
        setMainState({ type: 'loader', payload: true });
        setMsgs((prevMsgs) => [...prevMsgs, { type: 'user', message: txt }]);
        try {
            const response = await post('smart/ai', { message: txt });
            if (response.status === 200) {
                const obj = { type: 'gpt', message: response.data };
                setMsgs((prevMsgs) => [...prevMsgs, obj]);
            }
        } catch (error) {
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };

    return (
        <>
            <div className='container gpt'>
                <div className='chat'>
                    {msgs.map(a => {
                        return <span className={a.type}>{a.message} </span>
                    })}
                </div>
                <div className='snd'>
                    <textarea onChange={(a) => { setTxt(a.target.value) }}></textarea>
                    <p onClick={newMSG} className='btn btn-primary'> send </p>
                </div>
            </div>

        </>
    )
}

export default GPT