import React, { createContext, useReducer } from 'react';

const d = new Date();

const initialState = {
    selectedDate: { 'm': d.getMonth() + 1, 'y': d.getFullYear() },
    token: '',
    loader: false,
    scroll: true,
    selectedTable: [],
    notification: { Type: null, text: null },
    user: false,
    configurations: false,
    userId:false,
    reload:false,
    bookingId: false,
};

export const Context = createContext(initialState);

export const Store = ({ children }) => {
    const [mainState, setMainState] = useReducer((state, action) => {
        switch (action.type) {
            case 'notification':
                return { ...state, notification: action.payload };
            case 'selectedDate':
                return { ...state, selectedDate: action.payload };
            case 'loader':
                return { ...state, loader: action.payload };
            case 'selectedTable':
                return { ...state, selectedTable: action.payload };
            case 'configurations':
                return { ...state, configurations: action.payload };
            case 'user':
                return { ...state, user: action.payload };
            case 'token':
                return { ...state, token: action.payload };
            case 'lang':
                return { ...state, lang: action.payload };
            case 'scroll':
                return { ...state, scroll: action.payload };
            case 'bookingId':
                return { ...state, bookingId: action.payload };
            case 'userId':
                return { ...state, userId: action.payload };
            case 'reload':
                return { ...state, reload: action.payload };
            default:
                return {}
        }
    }, initialState);

    return (
        <Context.Provider value={[mainState, setMainState]}>{children}</Context.Provider>
    );
};
