import { Document, Page, Text, View, Font, StyleSheet, Image } from '@react-pdf/renderer';
import { country } from '../../helpers/getCountry';
import * as total from '../../helpers/getTotal';

export function Ebill(p) {


    const booking = p.dat.booking;
    const bookingDate = p.dat.bookingDate;
    const deposit = p.dat.deposit;
    const passport = p.dat.passport;
    const payment = p.dat.payments;
    const user = p.dat.user;
    // const [booking, bookingDate, deposit, passport, payment, user] = p.dat;

    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            display: "block"
        },
        title: {
            fontSize: 24,
            textAlign: 'center',
            fontFamily: 'Oswald'
        },
        author: {
            fontSize: 12,
            textAlign: 'center',
            marginBottom: 40,
        },
        subtitle: {
            fontSize: 18,
            margin: 12,
            fontFamily: 'Oswald'
        },
        text: {
            margin: 12,
            fontSize: 14,
            textAlign: 'justify',
            fontFamily: 'Times-Roman'
        },
        image: {
            marginVertical: 5,
            marginHorizontal: 230,
        },
        header: {
            fontSize: 14,
            marginBottom: 2,
            textAlign: 'center',
            color: 'grey',
        },
        subheader: {
            fontSize: 8,
            marginBottom: 20,
            textAlign: 'center',
            color: 'red',
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
        card: {
            width: '100%',
            padding: "10px",
            backgroundColor: "#dadada",
            borderRadius: "4px"
        },
        table: {
            width: "auto",
            borderStyle: "solid",
            textAlign: 'left',
            float: "left",
            display: "block",
        },
        tableRow: {
            textAlign: 'left',
            margin: "auto",
            flexDirection: "row",
            textAlign: 'left',
            float: "left",
            display: "block",
        },
        tableCol: {
            textAlign: 'left',
            width: "25%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 0,
            textAlign: 'left',
            float: "left",
            display: "block",
        },
        tableColL: {
            textAlign: 'start',
            width: "25%",
            textAlign: 'left',
            float: "left",
            display: "block",
        },
        tableColWL: {
            textAlign: 'left',
            width: "50%",
            border: "none",
            textAlign: 'left',
            float: "left",
            display: "block",

        },
        w50: {
            textAlign: 'left',
            width: "50%",
        },
        w50l: {
            textAlign: 'right',
            width: "50%",
        },
        tableColW: {
            textAlign: 'left',
            width: "50%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 0,
            textAlign: 'left',
            float: "left",
            display: "block",
        },
        tableCell: {
            marginTop: 5,
            textAlign: 'left',
            fontSize: 10
        },
        tableCellT: {
            marginTop: 5,
            textAlign: 'left',
            fontSize: 11,
            fontWeight: "bold",

        },
        tableCellrT: {
            marginTop: 5,
            textAlign: 'right',
            fontSize: 11,
            fontWeight: "bold",

        },
        tableCellr: {
            marginTop: 5,
            textAlign: 'right',
            fontSize: 10
        },
    });
    var today = new Date().toLocaleDateString();

    return (
        <>
            <Document>
                <Page style={styles.body}>
                    <Text style={styles.header} fixed>  Day-to-date summary {today}  </Text>
                    <Text style={styles.subheader} fixed> THIS IS NOT A FINALIZED INVOICE </Text>
                    <Text style={styles.title}>Holiday Mate</Text>
                    <View style={styles.tableRow}>
                        <View style={styles.w50}>
                            <Text style={styles.tableCell} >{user.name} {user.lastname} - {country(user.nationality)}</Text>
                            <Text style={styles.tableCell} >{user.email}</Text>
                            <Text style={styles.tableCell} >{user.phone}</Text>
                        </View>
                    </View>
                    <Text style={styles.subtitle}>
                        Summary
                    </Text>
                    <View style={styles.card}>
                        <View style={styles.tableRow}>
                            <View style={styles.w50}>
                                <Text style={styles.tableCell} >Booking </Text>
                                <Text style={styles.tableCell} >Extra Service</Text>
                                <Text style={styles.tableCell} >Paid Amount</Text>
                                <Text style={styles.tableCellT} >Total</Text>
                            </View>
                            <View style={styles.w50l}>
                                <Text style={styles.tableCellr} >{Number(total.booking(bookingDate)).toFixed(2)}</Text>
                                <Text style={styles.tableCellr} >{Number(total.payment(total.getExSer(payment))).toFixed(2)}</Text>
                                <Text style={styles.tableCellr} >{Number(total.payment(payment)).toFixed(2)}</Text>
                                <Text style={styles.tableCellrT} >{Number(Number(total.booking(bookingDate)) + Number(total.payment(total.getExSer(payment))) - Number(total.payment(payment))).toFixed(2)} AED</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.subtitle}>
                        Accommodation charges Breakdown
                    </Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColW}>
                                <Text style={styles.tableCell}>Service</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Date</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Price</Text>
                            </View>
                        </View>
                        {bookingDate.map((s, so) => {
                            return <View style={styles.tableRow} key={so} >
                                <View style={styles.tableColWL}>
                                    <Text style={styles.tableCell}>Bed Number - {s.bedNumber}</Text>
                                </View>
                                <View style={styles.tableColL}>
                                    <Text style={styles.tableCell}>{s.bookingDate}</Text>
                                </View>
                                <View style={styles.tableColL}>
                                    <Text style={styles.tableCell}>{s.amount}</Text>
                                </View>
                            </View>
                        })}
                    </View>
                    <Text style={styles.subtitle}>
                        Extra Service charges Breakdown
                    </Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColW}>
                                <Text style={styles.tableCell}>Service</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Date</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Price</Text>
                            </View>
                        </View>
                        {total.getExSer(payment).map((s, so) => {
                            return <View style={styles.tableRow} key={so} >
                                <View style={styles.tableColWL}>
                                    <Text style={styles.tableCell}> {s.name}</Text>
                                </View>
                                <View style={styles.tableColL}>
                                    <Text style={styles.tableCell}>{(new Date(s.date)).toISOString().slice(0, 10)}</Text>
                                </View>
                                <View style={styles.tableColL}>
                                    <Text style={styles.tableCell}>{s.amount}</Text>
                                </View>
                            </View>
                        })}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        </>
    )
}