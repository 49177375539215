import { useContext } from 'react';
import { Context } from '../../../context/store';

function Loader(props) {
    const [mainState] = useContext(Context);
    return (
        <>
            {mainState.loader ? <div className='load' > <span className='tar'></span></div> : <></>}
        </>
    );
}

export default Loader;
