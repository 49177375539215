const updateBodyClass = (className, state) => {
    if (className === 'hide') {
        document.body.style.overflow = 'hidden';
        state({ type: 'scroll', payload: false })
    } else {
        document.body.style.overflow = '';
        state({ type: 'scroll', payload: true })
    }
};
export default updateBodyClass;
