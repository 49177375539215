export function CheckIn(date1, date2, payment) {
    const checkin = new Date(date1);
    const checkout = new Date(date2);
    const daysbetween = (checkout.getTime() - checkin.getTime()) / (1000 * 3600 * 24);
    const paymentperday = payment / daysbetween;
    return paymentperday;
}

export function maskPhone(phone) {
    let phoneStr = phone.toString();
  
    if (phoneStr.length < 5) {
      return phoneStr; 
    }
  
    let start = Math.floor((phoneStr.length - 5) / 2);
    let end = start + 5;
  
    return phoneStr.substring(0, start) + '*****' + phoneStr.substring(end);
  }
  