import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

let analytics, app, messaging, requestNotificationPermission;

const configurations =  JSON.parse(localStorage.getItem('configurations')) 

const firebaseConfig = {
  apiKey: configurations?.firebase_apiKey,
  authDomain: configurations?.firebase_authDomain,
  projectId: configurations?.firebase_projectId,
  storageBucket: configurations?.firebase_storageBucket,
  messagingSenderId: configurations?.firebase_messagingSenderId,
  appId: configurations?.firebase_appId,
  measurementId: configurations?.firebase_measurementId,
};

// Check if debug mode is off
if (process.env.REACT_APP_DEBUG !== "True" && configurations?.firebase_apiKey ) {
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);

  // Get Firebase Cloud Messaging instance
  messaging = getMessaging(app);

  // Request notification permission and get token
  requestNotificationPermission = async () => {
    try {
      // Request notification permission if not granted
      if (Notification.permission !== "granted") {
        await Notification.requestPermission();
      }
      // Get FCM token
      const token = await getToken(messaging, { vapidKey: configurations?.firebase_vapidKey });
      return token;
    } catch (error) {
      console.error('Error requesting permission or getting token:', error);
      throw error; // Rethrow the error if necessary
    }
  };
} else {
  // Define dummy functions if debug mode is on
  analytics = {};
  app = {};
  messaging = {};
  requestNotificationPermission = async () => { };
}

export { analytics, app, messaging, requestNotificationPermission };
