import React, { useContext } from 'react';
import { Context } from '../../context/store';

function Rooms() {
    const [mainState] = useContext(Context);
    const validJSONStr = mainState.configurations.rooms;
    const data = JSON.parse(validJSONStr);

    const generateOptions = () => {
        let options = [];
        let counter = 1;

        data.forEach((room, roomIndex) => {
            let roomOptions = [];
            room.beds.forEach((bed, bedIndex) => {
                roomOptions.push(
                    <option key={`${room.room_name}-${bedIndex}`} value={counter}>
                        {bed}
                    </option>
                );
                counter++;
            });
            options.push(
                <optgroup key={`room-${roomIndex}`} label={room.room_name}>
                    {roomOptions}
                </optgroup>
            );
        });

        return options;
    };

    return <>{generateOptions()}</>;
}

export default Rooms;
