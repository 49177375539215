let beds = [
    { "id": "1", "value": "Bed - A" },
    { "id": "2", "value": "Bed - B" },
    { "id": "3", "value": "Bed - C" },
    { "id": "4", "value": "Bed - D" },
    { "id": "5", "value": "Bed - E" },
    { "id": "6", "value": "Bed - F" },
    { "id": "7", "value": "Bed - G" },
    { "id": "8", "value": "Bed - H" },
    { "id": "9", "value": "Bed - I" },
    { "id": "10", "value": "Bed - J" },
    { "id": "11", "value": "Bed - K" },
    { "id": "12", "value": "Bed - L" },
    { "id": "13", "value": "Bed - M" },
    { "id": "14", "value": "Bed - N" },
    { "id": "15", "value": "Bed - O" },
    { "id": "16", "value": "Bed - P" },
    { "id": "17", "value": "Bed - Q" },
    { "id": "18", "value": "Bed - R" },
    { "id": "19", "value": "Bed - S" },
    { "id": "20", "value": "Bed - T" },
    { "id": "21", "value": "Bed - U" },
    { "id": "22", "value": "Bed - V" },
    { "id": "23", "value": "Bed - W" },
    { "id": "24", "value": "Bed - X" },
    { "id": "25", "value": "Bed - Y" },
    { "id": "26", "value": "Bed - Z" },
]
export default beds;